import '../util/icons';
import { Logger } from './App/Helper/Logger';
import Amplify from 'aws-amplify';
import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

// Ignoring the dynamically generated file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore  */
import awsconfig from './aws-exports';
import { enableSentry } from './App/Helper/FeatureFlags';
import { UserTracking } from './lib/user-tracking/UserTracking';

function initErrorTracking() {
  if (enableSentry()) {
    Logger('Enabling Sentry');
    Sentry.init({
      environment: process.env.SENTRY_ENVIRONMENT,
      release: process.env.APP_VERSION,
      dsn: 'https://56fdafad4ccf43a683858c2ca3508c93@sentry.io/1874437',
      // This enables automatic instrumentation (highly recommended), but is not
      // necessary for purely manual usage
      integrations: [
        new TracingIntegrations.BrowserTracing({
          tracingOrigins: [process.env.V1_API_URL],
        }),
      ],

      // To set a uniform sample rate
      tracesSampleRate: 0.2,
    });
  } else {
    Logger('Sentry NOT enabled');
  }
}

function initAWS() {
  Logger('Enabling Cognito authentication');
  Amplify.configure(awsconfig);
}

async function initAppTelemetryAsync() {
  // TODO: implement
}

function initLogger() {
  // TODO: implement
}

export default async function bootstrapAsync(_: UserTracking): Promise<void> {
  initErrorTracking();
  initAWS();
  initLogger();
  await initAppTelemetryAsync();
}
