/*
 * A place for shared types and props.  (Unless it's appropriate to have them elsewhere.)
 */

import {
  GetBreeds_petBreeds,
  GetDraftUserPolicies_draftUserPolicies,
  GetDraftUserPolicies_draftUserPolicies_addOns,
  GetDraftUserPolicies_draftUserPolicies_answerInputs,
  GetDraftUserPolicies_draftUserPolicies_answerInputs_PolicyPetsAnswer,
  GetDraftUserPolicies_draftUserPolicies_answerInputs_PolicyPetsAnswer_Pets,
  GetDraftUserPolicies_draftUserPolicies_answerInputs_PolicyRentersAnswer,
  GetDraftUserPolicies_draftUserPolicies_answerInputs_PolicyRentersAnswer_AdditionalInterest,
  GetDraftUserPolicies_draftUserPolicies_answerInputs_PolicyTripAnswer,
  GetDraftUserPolicies_draftUserPolicies_coverage,
  GetDraftUserPolicies_draftUserPolicies_coverage_coverage,
  GetDraftUserPolicies_draftUserPolicies_items,
  GetDraftUserPolicies_draftUserPolicies_product,
  GetFinalizedUserPolicies_finalizedUserPolicies,
  GetProducts_products,
  SuggestLocation_suggestLocation_suggestions,
  SuggestLocation_suggestLocation_suggestions_address,
} from '../operation-result-types';
import {
  ColorValue,
  NativeSyntheticEvent,
  StyleProp,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
  TextStyle,
  ViewProps,
  ViewStyle,
} from 'react-native';
import React, { ForwardedRef } from 'react';
import { IconNode } from 'react-native-elements/dist/icons/Icon';

export type SuggestedAddressSuggestion =
  SuggestLocation_suggestLocation_suggestions;

export type SuggestedAddress =
  SuggestLocation_suggestLocation_suggestions_address;

export type Policy =
  | GetDraftUserPolicies_draftUserPolicies
  | GetFinalizedUserPolicies_finalizedUserPolicies; // perhaps also user policy?

export type PolicyLineItem = GetDraftUserPolicies_draftUserPolicies_items;

export type PolicyItems = GetDraftUserPolicies_draftUserPolicies_items[];

export type PolicyAddOn = GetDraftUserPolicies_draftUserPolicies_addOns;

export type PolicyCoverage = GetDraftUserPolicies_draftUserPolicies_coverage;

export type PolicyCoverageCoverage =
  GetDraftUserPolicies_draftUserPolicies_coverage_coverage; // distasteful!

export type PolicyProduct = GetDraftUserPolicies_draftUserPolicies_product;

export type PolicyAnswers = GetDraftUserPolicies_draftUserPolicies_answerInputs;

export type PolicyTripAnswers =
  GetDraftUserPolicies_draftUserPolicies_answerInputs_PolicyTripAnswer;

export type PolicyPetAnswers =
  GetDraftUserPolicies_draftUserPolicies_answerInputs_PolicyPetsAnswer_Pets;

export type PolicyPetsAnswers =
  GetDraftUserPolicies_draftUserPolicies_answerInputs_PolicyPetsAnswer;

export type PolicyPetsAnswersPet =
  GetDraftUserPolicies_draftUserPolicies_answerInputs_PolicyPetsAnswer_Pets;

export type PolicyRentersAnswers =
  GetDraftUserPolicies_draftUserPolicies_answerInputs_PolicyRentersAnswer;

export type PolicyRentersAdditionalInterest =
  GetDraftUserPolicies_draftUserPolicies_answerInputs_PolicyRentersAnswer_AdditionalInterest;

// GetDraftUserPolicies_draftUserPolicies_answerInputs_PolicyLifeAnswer | GetDraftUserPolicies_draftUserPolicies_answerInputs_PolicyTripAnswer;

export type Policies = Policy[];

export type Product = GetProducts_products;

export type Products = Product[];

export type PetBreed = GetBreeds_petBreeds;

export type PetBreeds = GetBreeds_petBreeds[];

export type FinalizedPolicy = GetFinalizedUserPolicies_finalizedUserPolicies;

export type FinalizedPolicies = FinalizedPolicy[];

export type PoliciesProps = {
  policies: Policies;
};

export type PolicyProps = {
  policy: Policy;
};

export type ProductProps = {
  product: Product;
};

export type PolicyProductProps = {
  product: PolicyProduct;
};

export type PolicyCoverageProps = {
  coverage: PolicyCoverage;
};

export type FinalizedPolicyProps = {
  policy: FinalizedPolicy;
};

export type FinalizedPoliciesProps = {
  policies: FinalizedPolicies;
};

export type WaffleTextInputProps = {
  ref?: ForwardedRef<TextInput>;
  label?: string;
  placeholder?: string;
  value?: string;
  footerText?: string;
  errorFooterText?: string;
  optional?: boolean;
  containerProps?: ViewProps;
  containerStyle?: StyleProp<ViewStyle>;
  placeholderTextColor?: ColorValue;
  otherProps?: TextInputProps;
  textInputStyle?: StyleProp<TextStyle>;
  onChangeText?: (s: string) => void;
  onValidate?: (s: string) => void;
  onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  onFocus?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
};

export type WaffleTextIconInputProps = {
  leftIcon?: IconNode;
};

export const HapticSelectionOptions = {
  enableVibrateFallback: true,
  ignoreAndroidSystemSettings: false,
};
