import { DarkText, WaffleOrange, White } from './App/Constants/Style';
import { WaffleTextFamily } from './App/Components/WaffleText';
import merge from 'deepmerge';
import { isNativeOrMobileWeb } from './App/Helper/DeviceHelper';
import { CardStyleInterpolators } from '@react-navigation/stack';
import { Platform } from 'react-native';

export const DefaultHeaderStyle = {
  color: DarkText,
  borderBottomWidth: 0,
  shadowColor: 'transparent',
};

const forFade = ({ current }) => ({
  cardStyle: {
    opacity: current.progress,
  },
});

export const DefaultNavStackOptions = {
  // initialRouteName: '',
  defaultNavigationOptions: {
    cardStyle: { backgroundColor: 'transparent', shadowColor: 'transparent' },
    headerStyle: DefaultHeaderStyle,
    headerTintColor: '#000',
    headerTitleStyle: {
      fontFamily: WaffleTextFamily,
      fontSize: 24,
      fontWeight: '400',
      lineHeight: 30,
      width: 300,
      textAlign: 'center',
    },
    navigationOptions: {
      tabBarLabel: 'foo',
    },
    gesturesEnabled: false,
    animationEnabled: Platform.OS !== 'web',
    cardStyleInterpolator: isNativeOrMobileWeb()
      ? CardStyleInterpolators.forHorizontalIOS
      : forFade,
    headerBackTitle: null,
    headerBackTitleVisible: false,
  },
};

export const ONBOARDING_STACK_CONFIG = merge.all([
  DefaultNavStackOptions,
  {
    headerMode: 'screen',
    gesturesEnabled: false,
    animationEnabled: Platform.OS !== 'web',
    cardStyleInterpolator: isNativeOrMobileWeb()
      ? CardStyleInterpolators.forHorizontalIOS
      : forFade,
    cardStyle: { backgroundColor: 'white' },
    headerTitleStyle: {
      fontFamily: WaffleTextFamily,
      fontSize: 24,
      fontWeight: '400',
      lineHeight: 30,
      width: 300,
      textAlign: 'center',
    },
    headerStyle: {
      ...DefaultHeaderStyle,
      justifyContent: 'center',
      alignItems: 'center',
      // height: ShortHeaderNavHeight,
    },
  },
]);

export const APP_CONFIG = merge.all([
  DefaultNavStackOptions,
  {
    headerMode: 'screen',
    gesturesEnabled: false,
    animationEnabled: Platform.OS !== 'web',
    cardStyleInterpolator: isNativeOrMobileWeb()
      ? CardStyleInterpolators.forHorizontalIOS
      : forFade,
    cardStyle: { backgroundColor: 'white' },
    headerTitleStyle: {
      fontFamily: WaffleTextFamily,
      fontSize: 24,
      fontWeight: '400',
      lineHeight: 30,
      width: 300,
      textAlign: 'center',
    },
    headerStyle: {
      backgroundColor: WaffleOrange,
      justifyContent: 'center',
      alignItems: 'center',
      // height: HeaderNavHeight,
    },
    headerTintColor: White,
  },
]);

export const WEB_HEADER_CONFIG = {
  gestureEnabled: false,
  headerShown: false,
};
